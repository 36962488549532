<template>
  <div id="app" v-if="!loading">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import api from "@/api";
import jwx from '@/utils/jweixin'
export default {
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    $route: "authenticate",
  },
  created() {
    console.log(this.$store);
  },
  mounted(){
    jwx.config()
  },
  methods: {
    authenticate() {
      let token = this.$store.getters["token"];
      if (!token) {
        let code = this.$route.query.code;
        if (!code) {
          this.$store.dispatch('user/refreshCode')
        } else {
          this.getTokenByCode(code);
        }
      } else {
        this.loading = false;
      }
    },
    async getTokenByCode(code) {
      try {
        let { token } = await api.getTokenByCode(code);
        this.$store.commit("user/setToken", token);
        this.loading = false;
      } catch (error) {
        this.$store.dispatch('user/refreshCode')
      }
    },
  },
};
</script>

<style lang="scss">
@import url("assets/css/index.scss");
html,
body,
#app {
  height: 100%;
  font-size: 16px;
}
</style>
