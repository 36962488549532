import request from '@/utils/request'
export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

export function getInfo(token) {
    return request({
        url: '/info',
        method: 'get',
        params: { token }
    })
}

export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
}

export function getComments({ page, size = 10 }) {
    return request({
        url: '/comments/listpage',
        method: 'get',
        params: { page, size }
    })
}




// 以上都是模拟接口

export function getBarrage(data) {
    return request({
        url: '/api/messageWall/barrage',
        method: 'post',
        data
    })
}

export function createMessage(data) {
    return request({
        url: '/api/messageWall/create',
        method: 'post',
        data
    })
}

export function getMessageList(data) {
    return request({
        url: '/api/messageWall/list',
        method: 'post',
        data
    })
}
export function praise(id) {
    return request({
        url: '/api/messageWall/praise',
        method: 'post',
        data: {
            message_wall_id: id
        }
    })
}
export function getMine(data) {
    return request({
        url: '/api/messageWall/mine',
        method: 'post',
        data
    })
}

export function getCommentDetails(id) {
    return request({
        url: '/api/messageWall/detail',
        method: 'post',
        data: { id }
    })
}

export function comment(data) {
    return request({
        url: '/api/messageWall/comment',
        method: 'post',
        data
    })
}

export function getTokenByCode(code) {
    return request({
        url: '/api/admin/auth/getTokenByCode',
        method: 'post',
        data: {
            code
        }
    })
}

export function getTicket(){
    return request({
        url: '/api/admin/auth/getJsapiTicket',
        method: 'post'
    })
}