import Vue from 'vue'
import App from './App.vue'
import 'normalize.css/normalize.css'
import router from './router'
import store from './store'
import './utils/rem'
// import Mock from './mock'
// Mock.bootstrap();
import './plugin'
import infiniteScroll from 'vue-infinite-scroll'

Vue.use(infiniteScroll)

import Bus from './utils/bus'
Vue.prototype.$bus = Bus;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
