import axios from 'axios'
import store from '../store'
import { Toast } from 'vant';

const service = axios.create({
    baseURL: 'https://edu.mirroredu.net/',
    timeout: 5000
})

service.interceptors.request.use(
    config => {
        if (store.getters.token) {
          config.headers['Authorization'] = store.getters['token']
        }
        config.headers['Content-Type']= 'application/json'
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 0) {
            console.log(res.msg)
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        console.log(error.response);
        if(error.response.data.code==-99){
            store.dispatch('user/refreshCode')
        }
        if(error.response.status==400){
            Toast(error.response.data.msg)
        }
        return Promise.reject(error)
    }
)

export default service