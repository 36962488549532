import { sha1 } from './sha1'
import api from "@/api";
function randomString(len) {
    len = len || 32;
    var $chars =
        "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var pwd = "";
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

function share() {
    wx.updateAppMessageShareData({
        title: '我们在Offer等待留言墙上一起...', // 分享标题
        desc: '分享你的留言让身边的人帮你点赞评论加油，切实的感受一下被爱的力量吧!', // 分享描述
        link: 'http://lyq.mirroredu.net/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://img.imgdb.cn/item/600d725b3ffa7d37b3702313.png', // 分享图标
        success: function () {
            // 设置成功
        }
    })
    wx.updateTimelineShareData({
        title: '我们在Offer等待留言墙上一起...', // 分享标题
        link: 'http://lyq.mirroredu.net/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://img.imgdb.cn/item/600d725b3ffa7d37b3702313.png', // 分享图标
        success: function () {
            // 设置成功
        }
    })
}
export default {
    config: function () {
        api.getTicket().then(({ ticket }) => {
            let str = randomString(16);
            let jsapi_ticket = ticket;
            let appId = "wxe3e14de394d0c405";
            let timestamp = new Date().valueOf().toString().substr(0, 10);
            let url = window.location.href;
            let signature = sha1(
                `jsapi_ticket=${jsapi_ticket}&noncestr=${str}&timestamp=${timestamp}&url=${url}`
            );
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr: str, // 必填，生成签名的随机串
                signature, // 必填，签名
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData'
                ], // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
                share()
            });
        })

    },
    share
}