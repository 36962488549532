import Vue from 'vue'
import VueRouter from 'vue-router'
import jwx from '@/utils/jweixin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/welcome'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '../views/main'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/barrage-wall',
    name: 'BarrageWall',
    component: () => import(/* webpackChunkName: "barrage-wall" */ '../views/barrage-wall'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/message-all',
    name: 'MessageAll',
    component: () => import(/* webpackChunkName: "message-all" */ '../views/message-all'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/message-my',
    name: 'MessageMy',
    component: () => import(/* webpackChunkName: "message-my" */ '../views/message-my'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/leave-message',
    name: 'LeaveMessageMy',
    component: () => import(/* webpackChunkName: "leave-message" */ '../views/leave-message'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/review/:id',
    name: 'Review',
    component: () => import(/* webpackChunkName: "review" */ '../views/review'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/more',
    name: 'More',
    component: () => import(/* webpackChunkName: "more" */ '../views/more'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/dry-goods',
    name: 'DryGoods',
    component: () => import(/* webpackChunkName: "dry-goods" */ '../views/dry-goods'),
    meta: {
      keepAlive: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to, from) => {
  jwx.share()
})
export default router
